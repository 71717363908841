<template>
  <div>
    <navbar title="会员权益"></navbar>
    <div>
      <div v-html="content"></div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      content: "",
    };
  },
  methods: {
    ...mapActions(["getquestion"]),
  },
  mounted() {
    this.getquestion({}).then((res) => {
      if (res.SuccessCode == 200) {
        this.content = res.ResponseBody.content;
      }
    });
  },
};
</script>

<style lang='scss' scoped>
</style>